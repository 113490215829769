import React, { useState } from 'react';
import './Contact.css';
import { useNavigate } from 'react-router';

const Contact = (props) => {
    const handler = props.handler;
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        companyName: '',
        fullName: '',
        email: '',
        phone: '',
        address: '',
        postalCode: '',
        projectDetails: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Handler:', handler);

        try {
            const result = handler(e, formData);
            
            if (result && typeof result.then === 'function') {
                result
                    .then(() => {
                        navigate('/fr/thank');
                    })
                    .catch((error) => {
                        console.error('Erreur lors de la soumission du formulaire:', error);
                        navigate('/fr/error');
                    });
            } else {
                navigate('/fr/thank');
            }
        } catch (error) {
            console.error('Erreur de traitement du formulaire:', error);
            navigate('/fr/error');
        }
    };
    

    return (
        <form className="contact-form container" onSubmit={handleSubmit} method="POST">
            <h1 className="mb-3">Bonjour !</h1>
            <h1 className="mb-3">Discutons de votre projet</h1>
            <p className="mb-5">Un projet à nous faire part ? Nous avons hâte de vous rencontrer.
                <br /> Renseignez vos besoins & vos envies !</p>
            <div className="form-group-row">
                <div className="form-group">
                    <label htmlFor="companyName">Commençons par votre nom d’entreprise</label>
                    <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                        placeholder="Nom d'entreprise"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="fullName">Nom et prénom</label>
                    <input
                        type="text"
                        id="fullName"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        placeholder="Nom et prénom"
                    />
                </div>
            </div>
            <div className="form-group-row">
                <div className="form-group">
                    <label htmlFor="email">Adresse Mail</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Exemple@gmail.com"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Téléphone</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Téléphone"
                    />
                </div>
            </div>
            <div className="form-group-row">
                <div className="form-group">
                    <label htmlFor="address">Adresse</label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        placeholder="Adresse"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="postalCode">Code postal</label>
                    <input
                        type="text"
                        id="postalCode"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleChange}
                        placeholder="Code postal"
                    />
                </div>
            </div>
            <div className="form-group-row-p">
                <label htmlFor="projectDetails">Votre projet</label>
                <textarea
                    id="projectDetails"
                    name="projectDetails"
                    value={formData.projectDetails}
                    onChange={handleChange}
                    placeholder="Votre projet"
                ></textarea>
            </div>
            <div className="form-group-row">
                <div className="form-group"></div>
                <div className="form-group">
                    <button type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: '8px' }}>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.526 0.0788072C19.4017 -0.269483 20.2695 0.598272 19.9212 1.47403L12.823 19.3227C12.4654 20.2218 11.1947 20.227 10.8298 19.3309L8.0602 12.5296C7.95107 12.2616 7.73842 12.0489 7.47042 11.9398L0.669132 9.17021C-0.226988 8.80529 -0.221792 7.53455 0.677283 7.177L18.526 0.0788072ZM18.4019 2.38729L11.8247 18.9257L9.05518 12.1244C9.00239 11.9948 8.93748 11.8716 8.86185 11.7563L18.4019 2.38729ZM17.7128 1.55832L8.06516 11.033C8.00371 11.0007 7.94044 10.9713 7.87556 10.9448L1.07427 8.17526L17.7128 1.55832Z" fill="white" />
                        </svg>
                        <span>Envoyer</span>
                    </button>
                </div>

            </div>
        </form>
    );
};

export default Contact;
