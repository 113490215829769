import React, { useEffect, useState } from "react";
import styled from "styled-components"

import Offre from "../../../Model/Offre";
import { URL_Back, URL_Dev } from "../Variables";
import { Link } from 'react-router-dom';
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";

import cookies from "js-cookie";

const Offres = (props) => {


  const { t } = props
  const [items, setItems] = useState([]);
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const [loading, setLoading] = useState('true')
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {
    window.scrollTo(0, 0);

    fetch(`${URL_Back}/wp-json/wp/v2/offres?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          var Offrelanguage = []
          currentLanguage === "fr"
            ? (result.map(element => { element.categories[0] === 96 && Offrelanguage.push(element) }))
            : currentLanguage === "en"
              ? (result.map(element => { element.categories[0] === 96 && Offrelanguage.push(element) }))
              : currentLanguage === "ar"
              && (result.map(element => { element.categories[0] === 96 && Offrelanguage.push(element) }))
          setItems(Offrelanguage)
          setLoading(false)
        }
      )

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let offre = [];
  items.map(element => (
    (langue === "fr" || langue === null) && (element.categories !== undefined && element.categories[0] === 68))
    ? offre.push(new Offre(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered, element.slug))
    : (langue === "en" && (element.categories !== undefined && element.categories[0] === 96))
      ? offre.push(new Offre(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered, element.slug))
      : (langue === "ar" && (element.categories !== undefined && element.categories[0] === 84))
      && offre.push(new Offre(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered, element.slug))
  )



  return (
    <>

      <div className="container" >



        {/* <Details className={langue === "ar" && "details-ar"} >
          <div className="left">
            <img className="image1" src="/images/nosOffresD'emplois/Mask Group 669.png" alt="Mask Group 669" />
            <img className="image2" src="/images/nosOffresD'emplois/Mask Group 781.png" alt="Mask Group 781" />
          </div>
          <div className="right">
            <Discription>
              <h2 className={langue === "ar" && "alignRightMobile"}>{t('Offres.recruiting')}</h2>
              <p>{t('Offres.growing')}
              </p>
              <h3>{t('Offres.Available')}</h3>
              <p>{t('Offres.looking')}</p>
            <Link className="link" to={langue === "fr"? "/fr/recrutement" : langue === "en" ? "/en/recrutement" : "/ar/توظيف"}>
              <button className={langue === "ar" ? "btn btn-primary button button-ar joindre" : "btn btn-primary button joindre"}><span className="pseudo-text">{t('Offres.Join')}</span></button>
            </Link>
            </Discription>
          </div>
        </Details> */}
        <LesOffres>
          <h1 className="mb-4 text-start">Rejoignez notre équipe</h1>
          <div className="d-flex offre">

            {offre.length > 0 ? (
              offre.map((item) => (
                <div key={item.id}>
                  <Link to={{ pathname: langue === "fr" ? `/fr/details-de-loffres-demplois/${item.slug}` : langue === "en" ? `/en/details-of-the-job-offer/${item.slug}` : `/ar/تفاصيل-عروض-العمل/${item.slug}`, state: item }} className="text-black text-decoration-none link">
                    <div className="card">
                      <h3 className="card-header">{item.title}</h3>
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-around" dangerouslySetInnerHTML={{ __html: item.excerpt.slice(3, -5) }} />
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p className="no-offers-message">Aucune offre pour le moment</p>
            )}
          </div>
        </LesOffres>
      </div >
    </>)
}


const Details = styled.div.attrs(props => ({
  className: props.className,
}))`
  &.details-ar{
    flex-direction: row-reverse;
  }
  padding: 0 10%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  @media (max-width:991px) {
    padding: 0px 6.5%;
    padding-top: 50px;
  }
  @media (max-width:600px) {
    padding: 50px 5% 0;
  }
  @media (max-width:600px) {
    padding: 50px 4% 0;
  }
  @media (max-width: 1334px){
    justify-content: center;
  }
  .left{
    width: 50%;
    min-width: 15rem;
    overflow: hidden;
    border-radius: 18px;
    >img{
      height: 100%;
    }
    .image2{
      display: none;
    }
    @media (max-width: 1025px){
      .image1{
        display: none;
      }
      .image2{
        display: block;
        width: 100%;
      }
    }
  }
    @media (max-width: 1025px){
      width: 100% !important;
      .left{
        width: 96%;
        margin-left: 25px;
      }
    }
    .right{
      width: 50%;
      @media (max-width: 1025px){
          padding-top: 30px;
          width: 100%;
          }
    }
`;

const Discription = styled.div`
  padding-left: 30px;
  line-height: 26px;
  >h2{
    padding-bottom: 16px;
   }
  >.joindre{
    margin-top:10px;
    margin-left: 0;
  }
  `;

const LesOffres = styled.div`
 @media (max-width: 576px) {
        padding: 20px ;
}

   h1{
   padding-top: 7% ;
   font-size: 60px;
   @media (min-width: 1024px) and (max-width: 1440px) {
        font-size: 45px;
        }
    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 38px;
   }
  @media (max-width: 768px) {
        font-size: 36px;
}
        @media (max-width: 480px) {

        font-size: 36px;
}

}
  .offre {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Pour espacer uniformément les colonnes */
  }

  .offre > div {
    width: calc(33.333% - 10px); /* Chaque carte prend environ un tiers de la largeur */
    margin-bottom: 20px; /* Espacement entre les lignes */
    box-sizing: border-box; /* Assure que la largeur inclut le padding et la bordure */
  }

  @media (max-width: 1200px) {
    .offre > div {
      width: calc(50% - 10px); /* 2 cartes par ligne sur les écrans de moins de 991px */
    }
  }

  @media (max-width: 640px) {
    .offre > div {
      width: 100%; /* 1 carte par ligne sur les petits écrans */
    }
  }

  .card {
    padding: 20px 20px 30px 20px;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
    overflow: hidden;
    border-bottom: 4px solid #0CA3FC;
    height: 100%;
    width: auto;
  }

  .card-header {
    background-color: transparent;
    border-bottom: 0;
    font-family: interRegular;
  }

  .card-body {
    padding: 10px 0;
    color: #000;
    font-weight: bold;
  }

`;


export default Offres;