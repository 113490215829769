import React from 'react';
import './Thank.css';

const Thank = () => {
    return (
        <div className="thank-you-page">
            <div className="content">
                <div className="icon">

                    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78" fill="none">
                        <g clip-path="url(#clip0_182_1284)">
                            <path d="M75.7701 10.6632C74.6227 9.5138 72.7609 9.51182 71.6134 10.6584L36.3469 45.8318L23.6228 32.0122C22.5234 30.8188 20.6645 30.7414 19.469 31.8407C18.2745 32.9402 18.198 34.8 19.2975 35.9945L34.094 52.0639C34.6359 52.6528 35.3943 52.9948 36.1939 53.0114C36.2154 53.0124 36.2361 53.0124 36.2567 53.0124C37.0338 53.0124 37.7814 52.7037 38.3321 52.155L75.7643 14.8208C76.9147 13.6745 76.9166 11.8127 75.7701 10.6632Z" fill="#0036AF" />
                            <path d="M75.0602 36.0602C73.4365 36.0602 72.1206 37.3762 72.1206 39C72.1206 57.2634 57.2634 72.1206 39 72.1206C20.7376 72.1206 5.8794 57.2634 5.8794 39C5.8794 20.7376 20.7376 5.8794 39 5.8794C40.6237 5.8794 41.9398 4.56346 41.9398 2.93978C41.9398 1.31595 40.6237 0 39 0C17.4952 0 0 17.4952 0 39C0 60.5039 17.4952 78 39 78C60.5039 78 78 60.5039 78 39C78 37.3763 76.6841 36.0602 75.0602 36.0602Z" fill="#0036AF" />
                        </g>
                        <defs>
                            <clipPath id="clip0_182_1284">
                                <rect width="78" height="78" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <h1>Nous avons bien reçu vos données !</h1>
                <p>Merci de l'intérêt que vous portez à notre société.</p>
                <button className="appointment-button">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path d="M6.125 0C5.6875 0 5.25 0.35 5.25 0.875V1.75H2.625C1.225 1.75 0 2.8875 0 4.375V7.875V25.375C0 26.8625 1.1375 28 2.625 28H25.375C26.8625 28 28 26.8625 28 25.375V7.875V4.375C28 2.8875 26.8625 1.75 25.375 1.75H22.75V0.875C22.75 0.35 22.4 0 21.875 0C21.35 0 21 0.35 21 0.875V1.75H7V0.875C7 0.35 6.65 0 6.125 0ZM26.25 25.375C26.25 25.9 25.9 26.25 25.375 26.25H2.625C2.1 26.25 1.75 25.9 1.75 25.375V8.75H26.25V25.375ZM21 3.5V4.375C21 4.9 21.35 5.25 21.875 5.25C22.4 5.25 22.75 4.9 22.75 4.375V3.5H25.375C25.9 3.5 26.25 3.85 26.25 4.375V7H1.75V4.375C1.75 3.85 2.1 3.5 2.625 3.5H5.25V4.375C5.25 4.9 5.6 5.25 6.125 5.25C6.65 5.25 7 4.9 7 4.375V3.5H21Z" fill="white" />
                            <path d="M5.3374 16.1875H8.8374C9.3624 16.1875 9.7124 15.8375 9.7124 15.3125V11.8125C9.7124 11.2875 9.3624 10.9375 8.8374 10.9375H5.3374C4.8124 10.9375 4.4624 11.2875 4.4624 11.8125V15.3125C4.4624 15.8375 4.8124 16.1875 5.3374 16.1875ZM6.2124 12.6875H7.9624V14.4375H6.2124V12.6875Z" fill="white" />
                            <path d="M12.3374 16.1875H15.8374C16.3624 16.1875 16.7124 15.8375 16.7124 15.3125V11.8125C16.7124 11.2875 16.3624 10.9375 15.8374 10.9375H12.3374C11.8124 10.9375 11.4624 11.2875 11.4624 11.8125V15.3125C11.4624 15.8375 11.8124 16.1875 12.3374 16.1875ZM13.2124 12.6875H14.9624V14.4375H13.2124V12.6875Z" fill="white" />
                            <path d="M19.3374 16.1875H22.8374C23.3624 16.1875 23.7124 15.8375 23.7124 15.3125V11.8125C23.7124 11.2875 23.3624 10.9375 22.8374 10.9375H19.3374C18.8124 10.9375 18.4624 11.2875 18.4624 11.8125V15.3125C18.4624 15.8375 18.8124 16.1875 19.3374 16.1875ZM20.2124 12.6875H21.9624V14.4375H20.2124V12.6875Z" fill="white" />
                            <path d="M5.3374 24.0625H8.8374C9.3624 24.0625 9.7124 23.7125 9.7124 23.1875V19.6875C9.7124 19.1625 9.3624 18.8125 8.8374 18.8125H5.3374C4.8124 18.8125 4.4624 19.1625 4.4624 19.6875V23.1875C4.4624 23.7125 4.8124 24.0625 5.3374 24.0625ZM6.2124 20.5625H7.9624V22.3125H6.2124V20.5625Z" fill="white" />
                            <path d="M12.3374 24.0625H15.8374C16.3624 24.0625 16.7124 23.7125 16.7124 23.1875V19.6875C16.7124 19.1625 16.3624 18.8125 15.8374 18.8125H12.3374C11.8124 18.8125 11.4624 19.1625 11.4624 19.6875V23.1875C11.4624 23.7125 11.8124 24.0625 12.3374 24.0625ZM13.2124 20.5625H14.9624V22.3125H13.2124V20.5625Z" fill="white" />
                            <path d="M19.3374 24.0625H22.8374C23.3624 24.0625 23.7124 23.7125 23.7124 23.1875V19.6875C23.7124 19.1625 23.3624 18.8125 22.8374 18.8125H19.3374C18.8124 18.8125 18.4624 19.1625 18.4624 19.6875V23.1875C18.4624 23.7125 18.8124 24.0625 19.3374 24.0625ZM20.2124 20.5625H21.9624V22.3125H20.2124V20.5625Z" fill="white" />
                        </svg>
                        <span>Prendre rendez-vous </span>
                    </div>
                </button>
            </div>
        </div>
    );
}

export default Thank;
