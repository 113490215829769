import React from 'react';
import './WebSolution.css';
import chartImage from '../../../../images/web/web.png';
import stat from '../../../../images/web/stat.png'
import gestion from '../../../../images/web/gestion.png'
import fidelisation from '../../../../images/web/fidelisation.png'
import shopIcon from '../../../../images/web/shop.png'
import eventIcon from '../../../../images/web/event.png'
import planningIcon from '../../../../images/web/planning.png'
import paymentIcon from '../../../../images/web/payment.png'
import ticketingIcon from '../../../../images/web/ticketing.png'
import slotIcon from '../../../../images/web/slot.png';
import formulaIcon from '../../../../images/web/formula.png';
import multiActivitiesIcon from '../../../../images/web/activities.png';


const WebSolution = () => {
    return (
        <div>
            <div className="web-solution-container ">
                <h1 className="web-solution-title">Solution web</h1>
                <div className="web-solution-content mt-5">
                    <div className="web-solution-text">
                        <h3>Un seul logiciel pour la gestion de toutes vos activités</h3>
                        <ul>
                            <li>

                                <img src={stat} className='img-fluid' />

                                <span>Statistiques des ventes et gestion commerciale</span>
                            </li>
                            <li>

                                <img src={gestion} className='img-fluid' />

                                <span>Gestion de la base de données clients</span>
                            </li>
                            <li>

                                <img src={fidelisation} className='img-fluid' />

                                <span>Fidélisation des clients</span>
                            </li>
                        </ul>
                    </div>
                    <div className="web-solution-image">
                        <img src={chartImage} alt="Graph on a laptop" />
                    </div>
                </div>

            </div>
            <div className="online-services-container">
                <h2>Réservation et vente en ligne de toutes vos activités</h2>
                <div className="services-cards">
                    <div className="service-card">
                        <img src={shopIcon} alt="Boutique en ligne" />
                        <span>Boutique en ligne</span>
                    </div>
                    <div className="service-card">
                        <img src={eventIcon} alt="Réservation d'événements" />
                        <span>Réservation d'évènement et de sessions</span>
                    </div>
                    <div className="service-card">
                        <img src={planningIcon} alt="Planification des activités" />
                        <span>Planification des activités</span>
                    </div>
                    <div className="service-card">
                        <img src={paymentIcon} alt="Paiement en ligne" />
                        <span>Paiement en ligne</span>
                    </div>
                    <div className="service-card">
                        <img src={ticketingIcon} alt="Billetterie" />
                        <span>Billetterie</span>
                    </div>
                </div>
            </div>
            <div className="birthday-reservation-container">
                <h2>Réservation et gestion des anniversaires en ligne</h2>
                <div className="services-cards-birthday mt-5">
                    <div className='card-birthday'>
                        <div className='d-flex align-items-center justify-center'>
                        <img src={slotIcon} alt="Gestion des créneaux" className='birth-img img-fluid' />
                        <div className="service-card-birthday">
                        <span>Gestion des créneaux</span>
                        </div>
                        </div>
                    </div>
                    <div className='card-birthday' >
                        <div className='d-flex align-items-center justify-center'>
                        <img src={paymentIcon} alt="Paiement en ligne" className='birth-img img-fluid'/>
                    <div className="service-card-birthday">
                        <span>Paiement en ligne</span>
                        </div>
                        </div>
                    </div>
                
                    <div className='card-birthday'>
                        <div className='d-flex align-items-center justify-center'>
                            <div className='d-flex'>
                        <img src={formulaIcon} alt="Formule personnalisable" className='birth-img img-fluid' />
                        <div className="service-card-birthday">
                        <span>Formule personnalisable</span>
                        </div>
                        </div>
                        </div>
                    </div>
                    <div  className='card-birthday'>
                        <div className='d-flex align-items-center justify-center'>
                        <img src={multiActivitiesIcon} alt="Multi-activités" className='birth-img img-fluid'/>
                        <div className="service-card-birthday">
                        <span>Gestion des formules multi-activités</span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebSolution;
