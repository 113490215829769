import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "./NavBar.css";
import { URL_Back, demo } from "../Pages/Variables";
import Menu from "../../Model/Menu";
import { withTranslation } from "react-i18next";
import cookies from "js-cookie";

const NavBar = (props) => {

  const { i18n } = props
  // const [items, setItems] = useState([]);
  const [menus, setMenus] = useState([]);
  const [classNameNavBar, setClassNameNavBar] = useState("");
  const [classLogo, setClassLogo] = useState("logo");
  const [expanded, setExpanded] = useState(false);
  const [langue, setLangue] = useState("fr");
  const [url, setUrl] = useState("");

  var verif = false;

  const [menuItems, setMenuItems] = useState([])

  const currentLanguage = cookies.get('i18next') || 'fr'

  const openMenuAPropos = (valeur) => {
    const updatedMenuItems = menuItems.map(element =>
      element.db_id === valeur ? { ...element, verif: true } : element
    );
    setMenuItems(updatedMenuItems);
  };


  const closeMenuAPropos = () => {
    const updatedMenuItems = menuItems.map(element => ({
      ...element,
      verif: false
    }));
    setMenuItems(updatedMenuItems);
  };


  const ChangeLangue = (val) => {
    if (val !== langue) {
      setLangue(val);
      i18n.changeLanguage(val);
      localStorage.setItem("lng", val);
      document.documentElement.setAttribute("lang", val);
    }

    document.body.dir = val === 'ar' ? 'rtl' : 'ltr';


    fetch(`${URL_Back}/wp-json/menus/v1/menus/${93}`)
      .then(res => res.json())
      .then(
        (result) => {
          const menuTabs = result.items.map(element =>
            new Menu(element.db_id, element.title, element.child_items, element.slug, verif)
          );
          setMenus(result);
          setMenuItems(menuTabs);
        },
        (error) => {
          console.log(error);
        }
      );


    const title = decodeURI(window.location.pathname)

    if (val === "en" && (title === "/fr/notre-agence" || title === "/ar/وكالتنا"))
      window.location.href = "/en/our-agency"
    if (val === "fr" && (title === "/en/our-agency" || title === "/ar/وكالتنا"))
      window.location.href = "/fr/notre-agence"
    if (val === "ar" && (title === "/en/our-agency" || title === "/fr/notre-agence"))
      window.location.href = "/ar/وكالتنا"
    if (val === "en" && (title === "/fr/notre-equipe" || title === "/ar/فريقنا"))
      window.location.href = "/en/our-team"
    if (val === "fr" && (title === "/en/our-team" || title === "/ar/فريقنا"))
      window.location.href = "/fr/notre-equipe"
    if (val === "ar" && (title === "/en/our-team" || title === "/fr/notre-equipe"))
      window.location.href = "/ar/فريقنا"
    if (val === "en" && (title === "/fr/nos-services" || title === "/ar/تطوير-الويب"))
      window.location.href = "/en/web-development"
    if (val === "fr" && (title === "/en/web-development" || title === "/ar/تطوير-الويب"))
      window.location.href = "/fr/nos-services"
    if (val === "ar" && (title === "/en/web-development" || title === "/fr/nos-services"))
      window.location.href = "/ar/تطوير-الويب"
    if (val === "en" && (title === "/fr/developpement-mobile" || title === "/ar/تطوير-المحمول"))
      window.location.href = "/en/mobile-development"
    if (val === "fr" && (title === "/en/mobile-development" || title === "/ar/تطوير-المحمول"))
      window.location.href = "/fr/developpement-mobile"
    if (val === "ar" && (title === "/en/mobile-development" || title === "/fr/developpement-mobile"))
      window.location.href = "/ar/تطوير-المحمول"
    if (val === "en" && title === "/ar/إدارة-المجتمع")
      window.location.href = "/en/community-management"
    if (val === "fr" && title === "/ar/إدارة-المجتمع")
      window.location.href = "/fr/community-management"
    if (val === "ar" && (title === "/en/community-management" || title === "/fr/community-management"))
      window.location.href = "/ar/إدارة-المجتمع"
    if (val === "en" && title === "/ar/محرك-البحث-الأمثل")
      window.location.href = "/en/search-engine-optimization"
    if (val === "fr" && title === "/ar/محرك-البحث-الأمثل")
      window.location.href = "/fr/search-engine-optimization"
    if (val === "ar" && (title === "/en/search-engine-optimization" || title === "/fr/search-engine-optimization"))
      window.location.href = "/ar/محرك-البحث-الأمثل"
    if (val === "en" && (title === "/fr/service-de-consultation" || title === "/ar/خدمة-استشارية"))
      window.location.href = "/en/service-provision"
    if (val === "fr" && (title === "/en/service-provision" || title === "/ar/خدمة-استشارية"))
      window.location.href = "/fr/service-de-consultation"
    if (val === "ar" && (title === "/en/service-provision" || title === "/fr/service-de-consultation"))
      window.location.href = "/ar/خدمة-استشارية"
    if (val === "en" && (title === "/fr/prestation-de-service" || title === "/ar/تقديم-الخدمة"))
      window.location.href = "/en/service-delivery"
    if (val === "fr" && (title === "/en/service-delivery" || title === "/ar/تقديم-الخدمة"))
      window.location.href = "/fr/prestation-de-service"
    if (val === "ar" && (title === "/en/service-delivery" || title === "/fr/prestation-de-service"))
      window.location.href = "/ar/تقديم-الخدمة"
    if (val === "en" && (title === "/ar/مَلَفّ" || title === "/fr/nos-references"))
      window.location.href = "/en/portfolio"
    if (val === "fr" && (title === "/ar/مَلَفّ" || title === "/en/portfolio"))
      window.location.href = "/fr/nos-references"
    if (val === "ar" && (title === "/en/portfolio" || title === "/fr/nos-references"))
      window.location.href = "/ar/مَلَفّ"
    if (val === "en" && (title === "/fr/nos-offres-demplois" || title === "/ar/عروض-عملنا"))
      window.location.href = "/en/our-job-offers"
    if (val === "fr" && (title === "/en/our-job-offers" || title === "/ar/عروض-عملنا"))
      window.location.href = "/fr/nos-offres-demplois"
    if ((val === "ar" && (title === "/en/our-job-offers" || title === "/fr/nos-offres-demplois")))
      window.location.href = "/fr/nos-offres-demplois"
    if (val === "en" && (title === "/fr/recrutement" || title === "/ar/توظيف"))
      window.location.href = "/en/recruiting"
    if (val === "fr" && (title === "/en/recruiting" || title === "/ar/توظيف"))
      window.location.href = "/fr/recrutement"
    if (val === "ar" && (title === "/en/recruiting" || title === "/fr/recrutement"))
      window.location.href = "/ar/توظيف"
    if (val === "en" && (title === "/fr/contact" || title === "/ar/اتصل-بنا"))
      window.location.href = "/en/contact-us"
    if (val === "fr" && (title === "/en/contact-us" || title === "/ar/اتصل-بنا"))
      window.location.href = "/fr/contact"
    if (val === "ar" && (title === "/en/contact-us" || title === "/fr/contact")) {
      window.location.href = "/fr/contact";
    }

    if (val === "en" && (title === "/fr/notre-solution-bowling" || title === "/ar/حل-البولينج-لدينا"))
      window.location.href = "/en/our-bowling-solution"
    if (val === "fr" && (title === "/en/our-bowling-solution" || title === "/ar/حل-البولينج-لدينا"))
      window.location.href = "/fr/notre-solution-bowling"
    if (val === "ar" && (title === "/en/our-bowling-solution" || title === "/fr/notre-solution-bowling"))
      window.location.href = "/ar/حل-البولينج-لدينا"

    if (val === "en" && (title === "/fr/mentions-legales" || title === "/ar/تعليمات-الاستخدام"))
      window.location.href = "/en/terms-of-use"
    if (val === "fr" && (title === "/en/terms-of-use" || title === "/ar/تعليمات-الاستخدام"))
      window.location.href = "/fr/mentions-legales"
    if (val === "ar" && (title === "/en/terms-of-use" || title === "/fr/mentions-legales"))
      window.location.href = "/ar/تعليمات-الاستخدام"

    if (val === "en" && (title === "/fr/notre-solution-bowling/bowling-de-limoges" || title === "/ar/حل-البولينج-لدينا/bowling-de-limoges"))
      window.location.href = "/en/our-bowling-solution/bowling-de-limoges"
    if (val === "fr" && (title === "/en/our-bowling-solution/bowling-de-limoges" || title === "/ar/حل-البولينج-لدينا/bowling-de-limoges"))
      window.location.href = "/fr/notre-solution-bowling/bowling-de-limoges"
    if (val === "ar" && (title === "/en/our-bowling-solution/bowling-de-limoges" || title === "/fr/notre-solution-bowling/bowling-de-limoges"))
      window.location.href = "/ar/حل-البولينج-لدينا/bowling-de-limoges"
    if (val === "en" && (title === "/fr/notre-solution-bowling/space-bowling" || title === "/ar/حل-البولينج-لدينا/space-bowling"))
      window.location.href = "/en/our-bowling-solution/space-bowling"
    if (val === "fr" && (title === "/en/our-bowling-solution/space-bowling" || title === "/ar/حل-البولينج-لدينا/space-bowling"))
      window.location.href = "/fr/notre-solution-bowling/space-bowling"
    if (val === "ar" && (title === "/en/our-bowling-solution/space-bowling" || title === "/fr/notre-solution-bowling/space-bowling"))
      window.location.href = "/ar/حل-البولينج-لدينا/space-bowling"
    if (val === "en" && (title === "/fr/notre-solution-bowling/extra-laser-bowling" || title === "/ar/حل-البولينج-لدينا/extra-laser-bowling"))
      window.location.href = "/en/our-bowling-solution/extra-laser-bowling"
    if (val === "fr" && (title === "/en/our-bowling-solution/extra-laser-bowling" || title === "/ar/حل-البولينج-لدينا/extra-laser-bowling"))
      window.location.href = "/fr/notre-solution-bowling/extra-laser-bowling"
    if (val === "ar" && (title === "/en/our-bowling-solution/extra-laser-bowling" || title === "/fr/notre-solution-bowling/extra-laser-bowling"))
      window.location.href = "/ar/حل-البولينج-لدينا/extra-laser-bowling"
    if (val === "en" && (title === "/fr/notre-solution-bowling/planet-bowling" || title === "/ar/حل-البولينج-لدينا/planet-bowling"))
      window.location.href = "/en/our-bowling-solution/planet-bowling"
    if (val === "fr" && (title === "/en/our-bowling-solution/planet-bowling" || title === "/ar/حل-البولينج-لدينا/planet-bowling"))
      window.location.href = "/fr/notre-solution-bowling/planet-bowling"
    if (val === "ar" && (title === "/en/our-bowling-solution/planet-bowling" || title === "/fr/notre-solution-bowling/planet-bowling"))
      window.location.href = "/ar/حل-البولينج-لدينا/planet-bowling"
    if (val === "en" && (title === "/fr/notre-solution-bowling/bowling-de-limoges" || title === "/ar/حل-البولينج-لدينا/bowling-de-limoges"))
      window.location.href = "/en/our-bowling-solution/bowling-de-limoges"
    if (val === "fr" && (title === "/en/our-bowling-solution/bowling-de-limoges" || title === "/ar/حل-البولينج-لدينا/bowling-de-limoges"))
      window.location.href = "/fr/notre-solution-bowling/bowling-de-limoges"
    if (val === "ar" && (title === "https://outlook.office365.com/owa/calendar/DIGISERVICEFORFUN@oxton-digital.com/bookings/s/r2PU4vNFNEeV21nViyrqbA2" || title === "https://outlook.office365.com/owa/calendar/DIGISERVICEFORFUN@oxton-digital.com/bookings/s/r2PU4vNFNEeV21nViyrqbA2"))
      window.location.href = "https://outlook.office365.com/owa/calendar/DIGISERVICEFORFUN@oxton-digital.com/bookings/s/r2PU4vNFNEeV21nViyrqbA2"
  }

  const title = window.location.pathname
  const splitState = title.split("/")

  useEffect(() => {
    if (splitState.length === 1) {
      setUrl("");
    } else if (splitState.length === 2) {
      setUrl("/" + splitState[1]);
    } else if (splitState.length === 3) {
      setUrl("/" + splitState[1] + "/" + splitState[2]);
    } else if (splitState.length === 4) {
      setUrl("/" + splitState[2] + "/" + splitState[3]);
    } else {
      setUrl("");
    }
  }, [splitState]);


  useEffect(() => {
    // setLangue(currentLanguage);
    // i18n.changeLanguage(currentLanguage);
    // localStorage.setItem("lng", currentLanguage);
    // document.documentElement.setAttribute("lang", currentLanguage);

    if (currentLanguage === "en") {
      fetch(`${URL_Back}/wp-json/menus/v1/menus/94`)
        .then(res => res.json())
        .then(
          (result) => {
            let menuTabs = [];
            setMenus(result);
            result.items.map(element =>
              menuTabs.push(new Menu(element.db_id, element.title, element.child_items, element.slug, verif))
            );
            setMenuItems(menuTabs);
          },
          (error) => {
            console.log(error);
          }
        );
    } else if (currentLanguage === "fr") {
      fetch(`${URL_Back}/wp-json/menus/v1/menus/94`)
        .then(res => res.json())
        .then(
          (result) => {
            let menuTabs = [];
            setMenus(result);
            result.items.map(element =>
              menuTabs.push(new Menu(element.db_id, element.title, element.child_items, element.slug, verif))
            );
            setMenuItems(menuTabs);
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      fetch(`${URL_Back}/wp-json/menus/v1/menus/94`)
        .then(res => res.json())
        .then(
          (result) => {
            let menuTabs = [];
            setMenus(result);
            result.items.map(element =>
              menuTabs.push(new Menu(element.db_id, element.title, element.child_items, element.slug, verif))
            );
            setMenuItems(menuTabs);
          },
          (error) => {
            console.log(error);
          }
        );
    }

    const onScroll = e => {
      if (e.target.documentElement.scrollTop < 100) {
        setClassNameNavBar("");
        setClassLogo("logo");
      } else if (e.target.documentElement.scrollTop > 120) {
        setClassNameNavBar("backgroundSizeAndColor");
        setClassLogo("logoBackground");
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [currentLanguage, verif]);


  return (
    <>{
      <Navbar expand="lg" className={langue === "ar" ? `${classNameNavBar} navbar-ar` : `${classNameNavBar}`} id="navbar" expanded={expanded}>
        <Nav.Link as={Link} to={"/"} className="nav-logo">
          <img className={`${classLogo}`} src="/images/digiServices.png" alt="digi-services" />
        </Nav.Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="lignes" onClick={() => setExpanded(expanded ? false : "expanded")} >
          <span className="my-1 mx-2 close">
            <img src="/images/navbar/close.svg" alt="close" />
          </span>
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {menuItems.map(item => (
              (item.title === "Demander une demo") ?
                <div className="d-flex div-langue" key={item.db_id}>
                  <Nav.Link className="link-demo" style={{ paddingRight: 0 }} as={Link} to={demo} target="_blank"
                    rel="noopener noreferrer" >
                    <button className={langue === "ar" ? "btn btn-primary button button-ar buttonNav" : "btn btn-primary button buttonNav"} type="submit" onClick={() => setExpanded(false)}>
                      <span className="pseudo-text">{item.title === "Demander une demo" ? "Demander une demo" : "Demander une demo"}</span>
                    </button>
                  </Nav.Link>
                  {/* <NavDropdown className={langue === "ar" ? "menu with_dropdown langue langue-ar" : "menu with_dropdown langue"} title={langue.charAt(0).toUpperCase() + langue.slice(1)} show={item.verif} onMouseEnter={() => openMenuAPropos(item.db_id)} onMouseLeave={closeMenuAPropos} onClick={() => item.verif ? closeMenuAPropos() : openMenuAPropos(item.db_id)}>
                    <NavDropdown.Item as={Link} to={"/fr" + url} onClick={() => (ChangeLangue("fr"), setExpanded(expanded ? false : "expanded"))}>Fr</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/en" + url} onClick={() => (ChangeLangue("en"), setExpanded(expanded ? false : "expanded"))}>En</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/ar" + url} onClick={() => (ChangeLangue("ar"), setExpanded(expanded ? false : "expanded"))}>Ar</NavDropdown.Item>
                  </NavDropdown> */}
                </div>
                :
                item.child_items !== undefined ?
                  window.innerWidth > 991 ?
                    <NavDropdown key={item.db_id} title={item.title} className="menu with_dropdown" show={item.verif} onMouseEnter={() => openMenuAPropos(item.db_id)} onMouseLeave={closeMenuAPropos} onClick={() => item.verif ? closeMenuAPropos() : openMenuAPropos(item.db_id)}>
                      {item.child_items.map(child => (
                        <NavDropdown.Item as={Link} to={"/" + langue + "/" + child.slug} key={child.db_id}
                          onClick={() => { setExpanded(false); closeMenuAPropos(); }}> {child.title} </NavDropdown.Item>

                      ))}
                    </NavDropdown>
                    : <NavDropdown key={item.db_id} title={item.title} className={langue === "ar" ? "menu with_dropdown menu_mobile menu_mobile-ar" : "menu with_dropdown menu_mobile"}>
                      {item.child_items.map(child => (
                        <NavDropdown.Item
                          as={Link}
                          to={`/${langue}/${child.slug}`}
                          key={child.db_id}
                          onClick={() => {
                            setExpanded(false);
                            closeMenuAPropos();
                          }}
                        >
                          {child.title}
                        </NavDropdown.Item>

                      ))}
                    </NavDropdown>
                  : <Nav.Link key={item.db_id} as={Link} to={"/" + langue + "/" + item.slug} onClick={() => setExpanded(false)} className="menu without-dropdown"><Nav.Item className="title_without_dropdown">{item.title}</Nav.Item></Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    }</>)
}
export default withTranslation()(NavBar)