import React, { useRef, useState } from 'react';
import './NotreSolution.css';
import BorneImage from '../../../images/notre-solution/borne.png'; 
import WebImage from '../../../images/notre-solution/web.png'; 
import qrImage from '../../../images/notre-solution/qr-code.png'; 
import video from '../../../images/notre-solution/V2 END.mp4'
import poster from '../../../images/notre-solution/poster.png'
import { FaPlay , FaPause  } from 'react-icons/fa';
import FAQ from './faq/Faq';

const NotreSolution = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoPlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  
  return (
    <div className='container-fluid notre-solution'>
      <div className='container'>
        <h1>Nos Solutions</h1>
        <p>
          Nos solutions ont été développées par des propriétaires de centres multi-activités, soucieux d’améliorer leur efficacité et accroitre leur rentabilité.
        </p>
        <div className='borne-container mt-5'>
        <div className='borne-section' onClick={()=>window.location.href='/fr/solution-web'}>
          <div className='borne'>
            <img src={WebImage} alt="Bornes" className="borne-image img-fluid" />
            <div className="borne-title" onClick={()=>window.location.href='/fr/solution-web'}>
              <span>WEB</span>
            </div>
            </div>
          </div>
          <div className='borne-section' onClick={()=>window.location.href='/fr/solution-borne'}>
            <div className='borne'>
            <img src={BorneImage} alt="Bornes" className="borne-image img-fluid" />
            <div className="borne-title" onClick={()=>window.location.href='/fr/solution-borne'}>
              <span>BORNES</span>
            </div>
            </div>
          </div>
         
          <div className='borne-section' onClick={()=>window.location.href='/fr/solution-qr-code'}>
            <div className='borne'>
            <img src={qrImage} alt="Bornes" className="borne-image img-fluid" />
            <div className="borne-title" onClick={()=>window.location.href='/fr/solution-qr-code'} >
              <span>QR CODE</span>
            </div>
          </div>
          </div>
        </div>
        
      </div>
      <div className="container video-container">
      <video
        ref={videoRef}
        src={video}
        poster={poster}
        controls
        loop
        className="video"
        onClick={handleVideoPlayPause}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
      {!isPlaying && (
        <div
          className="play-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleVideoPlayPause(e);
          }}
        >
          <FaPlay size={40} />
        </div>
      )}
    </div>
        <div className='container faq-container'>
            <FAQ/>

            <div className='conclusion'>
                <h5>Conclusion</h5>
                <span>
                L’expérience de Sophie Vivien démontre comment Digi Fun peut transformer la gestion des établissements de loisirs, avec une efficacité accrue, une réduction des erreurs, et une meilleure expérience client.
                </span>
            </div>

        </div>

    </div>
  );
};

export default NotreSolution;
