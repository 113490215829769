import React, { useState } from 'react';
import './Faq.css';

const faqData = [
  {
    id: 1,
    question: "Q1 : Pouvez-vous nous parler de Digiservice For Fun ?",
    answer: "Sophie Vivien : Digiservice for Fun est né de la collaboration de 4 centres multi activité. Fort d’une volonté commune de digitaliser nos parcours clients, nous nous sommes associés à 2 ingénieurs informaticien afin de développer une solution digitale complète, adaptée à nos activités, qui n’existait pas sur le marché."
  },
  {
    id: 2,
    question: "Q2 : Comment les bornes Digi Fun s'intègrent-elles dans votre fonctionnement quotidien ?",
    answer: "Sophie Vivien : Les bornes sont connectées à nos systèmes de caisse. Elles gèrent les paiements par carte, espèces et tickets CE. L'utilisation des bornes a permis à nos équipes de gagner un temps précieux car elles n'ont plus à gérer la prise de commande et l'encaissement"
  },
  {
    id: 3,
    question: "Q3 : Quels avantages avez-vous observés depuis l'adoption des bornes ?",
    answer: "Sophie Vivien : Nous avons vu une augmentation du chiffre d'affaires de 20 % sur le CA BAR. Notre masse salariale a également diminué. Pour le client, il n'y a plus de temps d'attente pour s’inscrire, ni pour passer commande au bar. Tout est plus fluide."
  },
  {
    id: 4,
    question: "Q4 : Quelles ont été les étapes importantes lors de l'implémentation ?",
    answer: "Sophie Vivien : Dans notre cas, l’installation a pris deux jours : une journée pour la mise en place, une demi-journée pour les tests, et une demi-journée pour la formation du personnel. Ces étapes ont facilité une transition fluide."
  },
  {
    id: 5,
    question: "Q5 : Les clients ont-ils bien réagi aux bornes de commande ?",
    answer: "Sophie Vivien : Oui, les clients apprécient la simplicité et la rapidité du service. Ils aiment être autonome dans leurs prises de commandes. Nous avons beaucoup de très bons retours concernant les bornes sur nos avis google."
  },
  {
    id: 6,
    question: "Q6 : L’utilisation des bornes a-t-elle affecté le contact avec les clients ?",
    answer: "Sophie Vivien : Le contact n’a pas disparu, il a simplement évolué. Les clients utilisent les bornes pour s'inscrire aux activités et passer leurs commandes, tandis que nos agents d’accueil les guident et les aident. Les bornes ont justement permis de dégager plus de temps à nos équipes afin de mieux s'occuper de la clientèle."
  },
  {
    id: 7,
    question: "Q7 : Comment les équipes ont-elles vu l'arrivée des bornes ?",
    answer: "Sophie Vivien : Il y avait un peu d'inquiétude au début, certaines personnes craignaient de se voir remplacer par une machine. Après quelques semaines d'utilisation tous les monde été conquis, à tel point qu'aujourd'hui, plus personne ne voudrait travailler sans. Grâce aux bornes, ils n’ont plus besoin de gérer les encaissements, les inscriptions ou les prises de commandes. Leurs travails est grandement simplifié."
  },
  {
    id: 8,
    question: "Q8 : En quoi Digi Fun se distingue-t-il des autres solutions ?",
    answer: "Sophie Vivien : Notre solution est unique en son genre. En effet sur une même borne vous pouvez par exemple vous inscrire pour une partie de bowling, commander à boire et a manger, ou encore faire une partie de VR, de laser... Si il existe déjà sur le marché des tablettes de prise de commandes pour le bar, nous sommes les seuls à proposer une borne qui va gérer le client sur place ainsi que son encaissement, deson arrivé jusqu'à son départ. L'ensemble est couplé avec de la réservation en ligne pour les activités et les anniversaires."
  },
  {
    id: 9,
    question: "Q9 : Quel impact a eu l’introduction des bornes sur la gestion des espèces ?",
    answer: "Sophie Vivien : La gestion des espèces est désormais simplifiée et sécurisée, éliminant les caisses traditionnelles et supprimant les risques de vol et d’erreurs de caisse. Nos bornes rejettent également la fausse monnaie."
  },
  {
    id: 10,
    question: "Q10 : Que recommanderiez-vous à d’autres établissements envisageant d’installer les bornes Digi Fun ?",
    answer: "Sophie Vivien : Je les recommande vivement. Développée par des propriétaires de bowling, la solution répond parfaitement aux besoins spécifiques des établissements de loisir. Avec un seul logiciel, vous pouvez gérer toutes vos activités en ligne ou sur place."
  }
  
];

  

const FAQ = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const toggleQuestion = (id) => {
    setActiveQuestion(activeQuestion === id ? null : id);
  };

  return (
    <div className="faq-container ">
      <h2>Interview de Sophie Vivien propriétaire du Bowling de Limoges</h2>
      
      {faqData.map((item) => (
        <div key={item.id} className="faq-item mt-4">
         <div 
            className={`faq-question ${activeQuestion === item.id ? 'active' : ''}`} 
            onClick={() => toggleQuestion(item.id)}
          >
            <div className='d-flex align-items-start flex-column'>
            <span>{item.question}</span>
            {activeQuestion === item.id && (
              <div className='answer mt-3'>
              <p>{item.answer}</p>
              </div>
         
          )}
          </div>
            <span className="faq-icon">
  {activeQuestion === item.id ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path d="M12.5 0C5.60722 0 0 5.60722 0 12.5C0 19.3928 5.60722 25 12.5 25C19.3928 25 25 19.3928 25 12.5C25 5.60722 19.3928 0 12.5 0ZM17.9688 13.5416H7.03125C6.45618 13.5416 5.98965 13.0751 5.98965 12.5C5.98965 11.9249 6.45618 11.4584 7.03125 11.4584H17.9688C18.5438 11.4584 19.0104 11.9249 19.0104 12.5C19.0104 13.0751 18.5438 13.5416 17.9688 13.5416Z" fill="#0CA3FC"/>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
<path d="M12.5 0C10.0277 0 7.61099 0.733112 5.55538 2.10663C3.49976 3.48015 1.89761 5.43238 0.951511 7.71645C0.0054161 10.0005 -0.242126 12.5139 0.24019 14.9386C0.722505 17.3634 1.91301 19.5907 3.66117 21.3388C5.40933 23.087 7.63661 24.2775 10.0614 24.7598C12.4861 25.2421 14.9995 24.9946 17.2835 24.0485C19.5676 23.1024 21.5199 21.5002 22.8934 19.4446C24.2669 17.389 25 14.9723 25 12.5C24.9961 9.18599 23.6779 6.00884 21.3345 3.66548C18.9912 1.32213 15.814 0.00390963 12.5 0ZM18.1818 13.6364H13.6364V18.1818C13.6364 18.4832 13.5166 18.7722 13.3035 18.9853C13.0904 19.1985 12.8014 19.3182 12.5 19.3182C12.1986 19.3182 11.9096 19.1985 11.6965 18.9853C11.4834 18.7722 11.3636 18.4832 11.3636 18.1818V13.6364H6.81819C6.5168 13.6364 6.22777 13.5166 6.01466 13.3035C5.80155 13.0904 5.68182 12.8014 5.68182 12.5C5.68182 12.1986 5.80155 11.9096 6.01466 11.6965C6.22777 11.4834 6.5168 11.3636 6.81819 11.3636H11.3636V6.81818C11.3636 6.5168 11.4834 6.22776 11.6965 6.01465C11.9096 5.80154 12.1986 5.68182 12.5 5.68182C12.8014 5.68182 13.0904 5.80154 13.3035 6.01465C13.5166 6.22776 13.6364 6.5168 13.6364 6.81818V11.3636H18.1818C18.4832 11.3636 18.7722 11.4834 18.9854 11.6965C19.1985 11.9096 19.3182 12.1986 19.3182 12.5C19.3182 12.8014 19.1985 13.0904 18.9854 13.3035C18.7722 13.5166 18.4832 13.6364 18.1818 13.6364Z" fill="#0CA3FC"/>
</svg>
  )}
</span>

          </div>
         
        </div>
      ))}
    </div>
  );
};

export default FAQ;
