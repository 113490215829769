import React, { useState } from 'react';
import './Carriere.css';
import { useNavigate } from 'react-router';
import Offres from '../../Offres/Offres';

const Carriere = (props) => {
    const handler = props.handler;
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        Name: '',
        fullName: '',
        email: '',
        phone: '',
        message: '',
        cv: ''
    });
    const [fileName, setFileName] = useState(''); // pour stocker le nom du fichier

    const handleChange = (e) => {
      const file = e.target.files[0]; // On récupère le premier fichier
      if (file) {
        setFormData({ ...formData, cv: file }); // On met à jour les données du formulaire
        setFileName(file.name); // On met à jour le nom du fichier
      }
    };
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({ ...formData, [name]: value });
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Handler:', handler);

        try {
            const result = handler(e, formData);

            if (result && typeof result.then === 'function') {
                result
                    .then(() => {
                        navigate('/fr/thank');
                    })
                    .catch((error) => {
                        console.error('Erreur lors de la soumission du formulaire:', error);
                        navigate('/fr/error');
                    });
            } else {
                navigate('/fr/thank');
            }
        } catch (error) {
            console.error('Erreur de traitement du formulaire:', error);
            navigate('/fr/error');
        }
    };


    return (
        <div className='container carriere'>

    
            <form className="carriere-form container" onSubmit={handleSubmit} method="POST">
                <h2 className="mb-3">Postuler à un emploi</h2>
                <div className="form-group-row">
                    <div className="form-group">
                        <label htmlFor="Name">Nom</label>
                        <input
                            type="text"
                            id="Name"
                            name="Name"
                            value={formData.Name}
                            onChange={handleChange}
                            placeholder="Nom"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="fullName">Prénom</label>
                        <input
                            type="text"
                            id="fullName"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            placeholder="Prénom"
                        />
                    </div>
                </div>
                <div className="form-group-row">
                    <div className="form-group">
                        <label htmlFor="email">Adresse Mail</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Exemple@gmail.com"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Téléphone</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Téléphone"
                        />
                    </div>
                </div>

                <div className="form-group-row-p">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Message"
                    ></textarea>
                </div>
                <div className="form-group-row-p">
      <label htmlFor="cv">CV</label>

      {/* Le label personnalisé qui déclenche l'input file */}
      <label htmlFor="cv" className='custom-file' style={{ cursor: 'pointer' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
          <path d="M33.917 20.0417C33.5081 20.0417 33.116 20.2042 32.8269 20.4933C32.5378 20.7824 32.3753 21.1745 32.3753 21.5834V28.0785C32.3741 29.2176 31.921 30.3098 31.1155 31.1153C30.31 31.9208 29.2179 32.3739 28.0787 32.3751H8.92195C7.78279 32.3739 6.69064 31.9208 5.88513 31.1153C5.07962 30.3098 4.62655 29.2176 4.62533 28.0785V21.5834C4.62533 21.1745 4.4629 20.7824 4.17378 20.4933C3.88466 20.2042 3.49253 20.0417 3.08366 20.0417C2.67478 20.0417 2.28265 20.2042 1.99354 20.4933C1.70442 20.7824 1.54199 21.1745 1.54199 21.5834V28.0785C1.54403 30.0351 2.32222 31.9111 3.70579 33.2946C5.08935 34.6782 6.96529 35.4564 8.92195 35.4584H28.0787C30.0354 35.4564 31.9113 34.6782 33.2949 33.2946C34.6784 31.9111 35.4566 30.0351 35.4587 28.0785V21.5834C35.4587 21.1745 35.2962 20.7824 35.0071 20.4933C34.718 20.2042 34.3259 20.0417 33.917 20.0417Z" fill="#0036AF" />
          <path d="M10.3402 13.4233L16.9586 6.80491V26.2083C16.9586 26.6172 17.121 27.0093 17.4101 27.2985C17.6993 27.5876 18.0914 27.75 18.5003 27.75C18.9091 27.75 19.3013 27.5876 19.5904 27.2985C19.8795 27.0093 20.0419 26.6172 20.0419 26.2083V6.80491L26.6603 13.4233C26.9511 13.7041 27.3405 13.8595 27.7447 13.856C28.1489 13.8525 28.5356 13.6903 28.8214 13.4045C29.1073 13.1187 29.2694 12.732 29.2729 12.3278C29.2764 11.9236 29.121 11.5341 28.8402 11.2434L19.5902 1.99337C19.3011 1.70435 18.9091 1.54199 18.5003 1.54199C18.0915 1.54199 17.6994 1.70435 17.4103 1.99337L8.1603 11.2434C7.87948 11.5341 7.72408 11.9236 7.7276 12.3278C7.73111 12.732 7.89325 13.1187 8.17908 13.4045C8.46492 13.6903 8.85159 13.8525 9.25581 13.856C9.66003 13.8595 10.0495 13.7041 10.3402 13.4233Z" fill="#0036AF" />
        </svg>
        <span> Télécharger votre CV</span>
      </label>

      {/* Le champ input pour sélectionner un fichier, caché */}
      <input
        type="file"
        id="cv"
        name="cv"
        onChange={handleChange}
        style={{ display: 'none' }} // Input caché
      />

      {/* Affichage du nom du fichier sélectionné */}
      {fileName && <p>Fichier sélectionné : {fileName}</p>}
    </div>


                <div className="form-group-row">
                    <div className="form-group"></div>
                    <div className="form-group">
                        <button type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: '8px' }}>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.526 0.0788072C19.4017 -0.269483 20.2695 0.598272 19.9212 1.47403L12.823 19.3227C12.4654 20.2218 11.1947 20.227 10.8298 19.3309L8.0602 12.5296C7.95107 12.2616 7.73842 12.0489 7.47042 11.9398L0.669132 9.17021C-0.226988 8.80529 -0.221792 7.53455 0.677283 7.177L18.526 0.0788072ZM18.4019 2.38729L11.8247 18.9257L9.05518 12.1244C9.00239 11.9948 8.93748 11.8716 8.86185 11.7563L18.4019 2.38729ZM17.7128 1.55832L8.06516 11.033C8.00371 11.0007 7.94044 10.9713 7.87556 10.9448L1.07427 8.17526L17.7128 1.55832Z" fill="white" />
                            </svg>
                            <span>Envoyer</span>
                        </button>
                    </div>

                </div>
            </form>

        </div>
    );
};

export default Carriere;
