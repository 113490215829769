import React from 'react';
import './BorneSolution.css';
import img from '../../../../images/borne/borne1.png';
import img1 from '../../../../images/borne/borne2.png'
import img2 from '../../../../images/borne/borne3.png'
import img3 from '../../../../images/borne/borne4.png'
import img4 from '../../../../images/borne/borne5.png'

const BorneSolution = () => {
  const content = [
    {
      imgSrc: img,
      text: "Nous vous proposons de gérer vos flux clients sur nos bornes interactives. Avec une interface intuitive et une grande capacité de personnalisation, nos parcours digitaux offrent une expérience utilisateur optimale. Nos bornes vous permettront de fluidifier votre accueil. Fini les queues interminables à l’accueil les soirs d’affluence. Vos clients entrent dans votre établissement, s’enregistrent et paient directement sur une borne pour n’importe quelle activité de votre centre."
    },
    {
      imgSrc: img1,
      text: "En acceptant aussi bien les espèces que les CB et les tickets CE dématérialisés, elles sécuriseront vos flux monétaires et remplaceront vos caisses. Plus d’erreurs possibles et plus d’argent qui circule entre les clients et les salariés. Un gain de temps et une tranquillité d’esprit assurés."
    },
    {
      imgSrc: img2,
      text: "Nos bornes de commande boosteront également votre chiffre d’affaires grâce au up selling et cross selling. Idéale pour une communication interactive de haute qualité, elles mettront en avant vos formules et vos offres promotionnelles."
    },
    {
      imgSrc: img3,
      text: "Nos parcours digitaux vous permettront d’absorber un flux important de clients simultanément et d’optimiser votre masse salariale. Les minutes passées sur nos bornes par vos clients sont autant de minutes économisées par vos équipes."
    },
    {
      imgSrc: img4,
      text: "Chacun de nos parcours de réservation en ligne se finalise sur nos bornes grâce à un QR code ou un numéro de réservation. Vos clients ayant réservé une activité en ligne, signale son arrivée sur une borne et finalise son paiement en espèce ou CB sans passer par l’accueil."
    },
  ];

  return (
    <div className='borne-solution-container'>
      <h1 className="web-solution-title">Solution Borne</h1>
      <div className="time-container mt-5">
        {content.map((item, index) => (
          <div key={index} className="time-item mt-4">
            <div className="time-image">
              <img src={item.imgSrc} alt={item.text.substring(0, 20)}   />
            </div>
            <div className="time-content">
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BorneSolution;
