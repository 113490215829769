import Cf7FormWrapper from "./Cf7FormWrapper";
import Contact from "./Contact";

const ContactForm = (props) => {
  return (
    <Cf7FormWrapper id={1596}>
      <Contact subprops={props} />
    </Cf7FormWrapper>
  );
}

export default ContactForm;
