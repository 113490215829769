export default class Pages {
  constructor(id, title, content, parent, slug, description, excerpt, date) {
    this.id = id;
    this.title = title;
    this.content = content;
    this.parent = parent;
    this.slug = slug;
    this.description = description;
    this.excerpt = excerpt;
    this.date = date;
  }

  // Méthodes pour 'id'
  setId(id) {
    this.id = id;
  }

  getId() {
    return this.id;
  }

  // Méthodes pour 'title'
  setTitle(title) {
    this.title = title;
  }

  getTitle() {
    return this.title;
  }

  // Méthodes pour 'content'
  setContent(content) {
    this.content = content;
  }

  getContent() {
    return this.content;
  }

  // Méthodes pour 'parent'
  setParent(parent) {
    this.parent = parent;
  }

  getParent() {
    return this.parent;
  }

  // Méthodes pour 'slug'
  setSlug(slug) {
    this.slug = slug;
  }

  getSlug() {
    return this.slug;
  }

  // Méthodes pour 'description'
  setDescription(description) {
    this.description = description;
  }

  getDescription() {
    return this.description;
  }

  // Méthodes pour 'excerpt'
  setExcerpt(excerpt) {
    this.excerpt = excerpt;
  }

  getExcerpt() {
    return this.excerpt;
  }

  // Méthodes pour 'date'
  setDate(date) {
    this.date = date;
  }

  getDate() {
    return this.date;
  }
}
