import React from 'react';
import './BowlingLocations.css'
import MapContainer from '../../../Map/MapComponent';

const BowlingLocations = () => {
    const locations = [
        {
            name: 'Limoges de Bowling',
            logo: "../../images/home/limoges-bowling.png",
            address: 'Le Bas Faure Route de Boisseuil 87220 Feytiat',
            email: 's.vivien@digiserviceforfun.com',
        },
        {
            name: 'Planet Bowling',
            logo: '../../images/home/planet-bowling.png',
            address: 'Z.A du, 8 Rue du Château d\'Isenghien, 59160 Lomme',
            email: 'j.rohart@digiserviceforfun.com',
        },
        {
            name: 'Space Bowling',
            logo: '../../images/home/space-bowling.png',
            address: '130 Rue Michel Debré, 30000 Nimes',
            email: 'b.pignan@digiserviceforfun.com',
        },
    ];
    const map = [
        {
          name: 'Limoges de Bowling',
          lat: 45.8325, // Example latitude
          lng: 1.2611, // Example longitude
          address: 'Le Bas Faure Route de Boisseuil 87220 Feytiat',
          email: 's.vivien@digiserviceforfun.com',
        },
        {
          name: 'Planet Bowling',
          lat: 50.6113, // Example latitude
          lng: 3.0498, // Example longitude
          address: 'Z.A du, 8 Rue du Château d\'Isenghien, 59160 Lomme',
          email: 'j.rohart@digiserviceforfun.com',
        },
        {
          name: 'Space Bowling',
          lat: 43.8346, // Example latitude
          lng: 4.3601, // Example longitude
          address: '130 Rue Michel Debré, 30000 Nimes',
          email: 'b.pignan@digiserviceforfun.com',
        },
      ];
    return (
        <div className="Location d-flex flex-column ">
            <h2 className='mb-5'>Vous pouvez nous trouver dans nos locaux</h2>
            <div className='div'>

            <div className="locations-container ">
                {locations.map((location) => (
                    <div key={location.name} className="location-card d-flex">
                        <div className="logoL">
                            <img src={location.logo} alt={`Logo of ${location.name}`} />
                        </div>
                        <div className='info'>
                            <h3>{location.name}</h3>
                            <div className='d-flex flex-direction-row align-items-center gap-2'>
                                <img className='loc-icon' src='../images/icons/Pin.png' alt=''/>
                            <p>{location.address}</p>
                            </div>
                            <div className='d-flex flex-direction-row align-items-center gap-2'>
                            <img className='mes-icon' src='../images/icons/Vector.png' alt=''/>
                            <a href={`mailto:${location.email}`}>
                                {location.email}
                            </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className=" map-container">
            <MapContainer locations={map} />
            </div>
            </div>
        </div>
    );
};

export default BowlingLocations;
