
import NavBar from './components/Layout/NavBar';

import Footer from './components/Layout/Footer';
import { BrowserRouter } from "react-router-dom";

import Home from './components/Pages/HomePage/Home';
import { Route, Routes } from "react-router";

import Thank from './components/Pages/ThankPage/Thank';
import WhoAreWe from './components/Pages/WhoAreWePage/WhoAreWePage';
import NotFound from './components/Pages/NotFound/NotFound';
import MentionsLegales from './components/Pages/MentionsLegales/MentionsLegales';
import ContactForm from './components/Pages/Forms/ContactPage/ContactForm';
import ErrorPage from './components/Pages/ErrorPage/ErrorPage';
import NotreSolution from './components/Pages/NotreSolution/NotreSolution';
import CarriereForm from './components/Pages/Forms/Carriere/CarriereForm';
import WebSolution from './components/Pages/NotreSolution/Web/WebSolution';

import BorneSolution from './components/Pages/NotreSolution/borne/BorneSolution';
import DetailsOffres from './components/Pages/Offres/DetailsOffres';
import QrCode from './components/Pages/NotreSolution/qrCode/QrCode';


const App = () => {

  // useEffect(() => {
  //   const TRACKING_ID = "G-76VTJ6WD4Z"
  //   ReactGA.initialize(TRACKING_ID);
  //   ReactGA.pageview(location.pathname + window.location.search);
  // }, [])

  return (
    <section id='Oxton'>
   
 
      <BrowserRouter>
        <NavBar/>
    
       
       <Routes>
    
        <Route path='/' index element={<Home/>} /> 
        <Route path='/fr/contact-digi' index element={<ContactForm/>} />
        <Route path='/fr/carriere-digi' index element={<CarriereForm/>} />
        <Route path='/fr/thank' index element={<Thank/>} />
        <Route path="/fr/details-de-loffres-demplois/:slug" index element={ <DetailsOffres/> }/>

        <Route path="/fr/error" element={<ErrorPage/>} />
        <Route path='/fr/qui-sommes-nous' index element={<WhoAreWe/>} />
        <Route path='/fr/mentions-legales' index element={<MentionsLegales/>} />
        <Route path='/fr/notre-solution' index element={<NotreSolution/>}/>
        <Route path='/fr/solution-web' index element={<WebSolution/>} />
        <Route path='/fr/solution-borne' index element={<BorneSolution/>} />
        <Route path='/fr/solution-qr-code' index element={<QrCode/>} />
        <Route path='*' element={<NotFound/>} />
       
      </Routes>
    

      <Footer/>
    
      </BrowserRouter>
   
    </section>
  )
}
export default App 