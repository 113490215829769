import React from 'react';
import './Timeline.css';

const Timeline = () => {
  return (
    <div className="timeline-container">
      <h2>Notre histoire</h2>
      <div className="timeline">
        <div className="timeline-event">
          <div className="timeline-info">
            <div className="timeline-date">2021</div>
            <div className="timeline-title">Collaboration</div>
          </div>
          <div className="timeline-box top">
            <div className="timeline-content">
              Rencontre de 4 centres de loisirs autour d’un projet commun, celui d’optimiser leurs centres en créant un logiciel 100% sur mesure.
            </div>
          </div>
          <div className="timeline-point"></div>
        </div>
        <div className="timeline-event">
          <div className="timeline-point"></div>
          <div className="timeline-box bottom">
            <div className="timeline-content">
              Tests, amélioration, fiabilisation de la solution digitale dans les 4 centres .
            </div>
          </div>
          <div className="timeline-info">
            <div className="timeline-date">2022</div>
            <div className="timeline-title">Lancement</div>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-info">
            <div className="timeline-date">2024</div>
            <div className="timeline-title">Commercialisation</div>
          </div>
          <div className="timeline-box top">
            <div className="timeline-content">
              Naissance de DigiserviceForFun dans le but de commercialiser la solution et la proposer aux centres de loisirs, restaurants, bar, franchises, ….
            </div>
          </div>
          <div className="timeline-point"></div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
