import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
    return (
        <div className="notfound-container">
            <h1>404 - Page Non Trouvée</h1>
            <p>Désolé, la page que vous recherchez n'existe pas.</p>
            <Link to="/" className="notfound-button">Retour à l'accueil</Link>
        </div>
    );
}

export default NotFound;
