import React, { cloneElement, useState } from "react"
import { URL_Back } from "../../Variables";

const jsonToFormData = (json) => {
  try {
    const data = new FormData()

    for (let k in json) {
      data.append(k, json[k])
    }
    return data
  } catch (error) {
    console.error(error)
    return null
  }
}

const ErrorMessage = () => {
  return (
    <div style={{ color: "red" }}>
      <strong>url</strong> or <strong>siteUrl</strong> and{" "}
      <strong>formId</strong> are mandatory attributes
    </div>
  )
}

const Cf7FormWrapper = ({ children, siteUrl, formId, id }) => {
  const [issent, setSent] = useState(false)
  const [isloading, setLoading] = useState(false)
  const [haserror, setError] = useState(null)

  const apiUrl = `${URL_Back}/wp-json/contact-form-7/v1/contact-forms/${id}/feedback/`;

  const formSubmitHandler =async (event, payload) => {
    event.preventDefault();

    setLoading(true);
    setError(null);

    return await fetch(apiUrl, {
      method: "POST",
      body: jsonToFormData(payload),
    })
      .then((resp) => {
        console.log('Response Status:', resp.status); // Ajouter un log
        return resp.json();
      })
      .then((resp) => {
        console.log('Response Data:', resp); // Ajouter un log
        if (resp.status !== "mail_sent") {
          throw new Error(resp.message || 'Erreur inconnue'); // Assurez-vous que les erreurs sont correctement lancées
        }
        setSent(true);
      })
      .catch((error) => {
        setError(error);
        console.error('Handler Error:', error); // Ajouter un log
        throw error; // Propager l'erreur pour que `handleSubmit` puisse la capturer
      })
      .finally(() => {
        setLoading(false);
      });
};


  const Form = cloneElement(children, {
    handler: formSubmitHandler,
    isloading,
    issent,
    haserror,
  })

  return <div>{id || (siteUrl && formId) ? Form : <ErrorMessage />}</div>
}

export default Cf7FormWrapper