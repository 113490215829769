import React, { useEffect, useState } from "react";
import { URL_Dev } from "../Variables";
import { Helmet } from 'react-helmet-async';
import './Home.css';
import BowlingLocations from "./BowlingLocations/BowlingLocations";

const Home = () => {
  let titre = "Accueil digi services for fun";
  const cards = [
    {
      title: 'Billetterie',
      subtitle: 'Gestion des tickets CE dématérialisés',
      description: '<strong>Flexibilité</strong> totale adaptée à chaque activité : configurez facilement vos tickets CE. Notre plateforme permet une <strong>personnalisation</strong> complète, répondant aux besoins spécifiques de chaque client entreprise',
      fullDescription: 'Flexibilité totale adaptée à chaque activité : configurez facilement vos tickets CE. Notre plateforme permet une personnalisation complète, répondant aux besoins spécifiques de chaque client entreprise',
      link: '#',
      icon: '../images/icons/billeterie.png'
    },
    {
      title: 'Réservation en ligne',
      subtitle: 'Réservation en ligne pour toutes activités',
      description: "Intégration parfaite : notre plateforme de <strong>réservation en ligne</strong> s'intègre de manière fluide à votre site internet. Elle offre une expérience utilisateur optimale avec une <strong>option de paiement flexible</strong>",
      fullDescription: 'Réservation en Ligne pour Toutes Activités avec plus de détails.',
      link: '#',
      icon: '../images/icons/reservation.png'
    },
    {
      title: 'Parcours activités',
      subtitle: 'Digitalisation des parcours clients pour toutes activités',
      description: "<strong>Optimisez l'expérience</strong> de vos clients avec <strong>nos bornes interactives multifonctionnelles</strong>. Que ce soit pour un verre, une partie de bowling, de laser game, une session VR... Vos clients réservent et payent leurs parties sur nos bornes, en CB, espèces et Tickets CE !",
      fullDescription: "Optimisez l'expérience de vos clients avec nos bornes interactives multifonctionnelles. Que ce soit pour un verre, une partie de bowling, de laser game, une session VR... Vos clients réservent et payent leurs parties sur nos bornes, en CB, espèces et Tickets CE !",
      link: '#',
      icon: '../images/icons/parcours.png'
    },
    {
      title: 'Gestion',
      subtitle: 'Logiciel de gestion et statistique',
      description: "<strong>Personnalisation Complète</strong>: notre logiciel de gestion est entièrement paramétrable via un <strong> back office intuitif</strong>, conçu pour s'adapter parfaitement à votre méthode de travail. Modifiez les paramètres pour répondre aux <strong>besoins spécifiques</strong>",
      fullDescription: "<strong>Personnalisation Complète </strong>: Notre logiciel de gestion est entièrement paramétrable via un <strong>back office intuitif</strong>, conçu pour s'adapter parfaitement à votre méthode de travail. Modifiez les paramètres pour répondre aux <strong>besoins spécifiques</strong>",
      link: '#',
      icon: '../images/icons/gestion.png'
    },
    {
      title: 'Parcours Bar',
      subtitle: 'Prise de commande bar',
      description: "<strong>Intégration avec votre logiciel de caisse </strong>: connectez facilement notre système à votre logiciel de caisse pour un contrôle précis et sécurisé de vos flux financiers. Notre solution assure une <strong> gestion efficace de vos transactions</strong>",
      fullDescription: "Intégration avec Votre Logiciel de Caisse : Connectez facilement notre système à votre logiciel de caisse pour un contrôle précis et sécurisé de vos flux financiers. Notre solution assure une <strong>gestion efficace de vos transactions</strong>",
      link: '#',
      icon: '../images/icons/parcour.png'
    },
    {
      title: 'Personnalisation sur mesure',
      subtitle: 'Personalisation de logiciel et de matériel',
      description: "<strong>Modification à la demande</strong> de votre logiciel en fonction de vos besoins, <strong>customisation des bornes</strong> a l’effigie de votre établissement. Création de votre site internet, application mobile personnalisable",
      fullDescription: "Modification à la demande de votre logiciel en fonction de vos besoins, customisation des bornes a l’effigie de votre établissement. Création de votre site internet, application mobile personnalisable",
      link: '#',
      icon: '../images/icons/personnalistaion.png'
    },
  ];

  const [expandedCards, setExpandedCards] = useState(Array(cards.length).fill(false));

  const toggleExpand = (index) => {
    const newExpandedCards = [...expandedCards];
    newExpandedCards[index] = !newExpandedCards[index];
    setExpandedCards(newExpandedCards);
  };

  useEffect(() => {
    const slider = document.querySelector(".activities");
    const items = Array.from(slider.querySelectorAll(".activity-item"));
    
    // Duplicate items for seamless scrolling
    const clone = slider.firstElementChild.cloneNode(true);
    slider.appendChild(clone);

    const totalWidth = items.reduce((acc, item) => acc + item.offsetWidth, 0); // Total width of items including clone
    let position = 0;
    const speed = 0.2; // Adjust speed as needed

    function scroll() {
      position -= speed;
      if (Math.abs(position) >= totalWidth ) {
        position = 0;
      }
      slider.style.transform = `translateX(${position}px)`;
      requestAnimationFrame(scroll);
    }

    scroll();
  }, []);

  return (
    <>
      {/* <Helmet>
        <title>{titre}</title>
        <meta name="description" content="" />
        <link rel="canonical" href={`${URL_Dev}`} />
      </Helmet> */}
      <div className="banner" >
      </div>
      <div className="container-fluid home">

        <h1 className="mt-5 sol">La solution de digitalisation qui vous fait gagner du <br /> temps et de l'argent.</h1>
        <p className="pTitle">
          <span className="special">Solution digitale</span> pour accueillir et <span className="special">encaisser</span> vos clients, <span className="special">optimiser</span> et <span className="special">développer</span> votre centre de loisir.<br /> Un seul logiciel pour gérer toutes vos activités.
        </p>

        <div className="cards mb-5">
          {cards.map((card, index) => (
            <div className={`card ${expandedCards[index] ? 'expanded' : ''}`} key={index}>
              <div className="d-flex align-items-center gap-4">
                <img className="img-fluid" src={card.icon} alt={`${card.title} icon`} />
                <div>
                  <h3>{card.title}</h3>

                </div>
              </div>
              <h4>{card.subtitle}</h4>
              <p dangerouslySetInnerHTML={{ __html: card.description }}></p>

            </div>
          ))}
        </div>
        <div className="rectangle mb-4">
          <p> La première solution digitale qui gère vos espèces</p>
        </div>
        <div className="activities-slider">
  <div className="activities">
    <div className="activity-item">
      <span className="standard">Laser</span>
      <span className="highlight ellipse">&middot;</span>
      <span className="highlight">Bowling</span>
      <span className="highlight ellipse">&middot;</span>
      <span className="standard">Quiz</span>
      <span className="highlight ellipse">&middot;</span>
      <span className="highlight">Bar</span>
      <span className="highlight ellipse">&middot;</span>
      <span className="standard">VR</span>
      <span className="highlight ellipse">&middot;</span>
      <span className="highlight">Karaoké</span>
      <span className="highlight ellipse">&middot;</span>
      <span className="standard">Anniversaire</span>
      <span className="highlight ellipse">&middot;</span>
    
    </div>
 
   
  </div>
</div>



        <div className="professional-equipment ">
          <h2>Un matériel professionnel</h2>
          <p>Nos <span>bornes</span> s'adaptent facilement à vos centres et se connectent à vos <br /> systèmes de caisse existants</p>
          <div className="equipment-cards">
            <div className="equipment-card">
              <img src="../images/home/equipement1.png" alt="Borne de paiement carte et espèces" className="eq1" />
              <h3>Borne de paiement carte et espèces</h3>
              <ul>
                <li>Paiement par carte de crédit</li>
                <li>Paiement en espèces</li>
                <li>Rejette les faux billets et les fausses pièces</li>
                <li>Accepte, valide et distribue des pièces de 2 ct à 2 €</li>
              </ul>
            </div>
            <div className="equipment-card">
              <img src="../images/home/equipement2.png" alt="Borne de paiement par carte" className="eq2"/>
              <h3>Borne de paiement par carte</h3>
              <ul>
                <li>Paiement des produits et services par carte de crédit</li>
                <li>Principaux accessoires déjà inclus</li>
                <li>Écran tactile 21"</li>
                <li>Fonctionne de manière autonome et sans interruption</li>
              </ul>
            </div>
            <div className="equipment-card">
              <img src="../images/home/equipement3.png" alt="Borne murale" className="eq2" />
              <h3>Borne murale</h3>
              <ul>
                <li>Encaissement de produits ou services par carte de crédit</li>
                <li>Lecteur de QR code</li>
                <li>Fonctionne de manière autonome et sans interruption</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="rectangle-2 ">
          <p>Solution  360° de bornes  interactives <span>qui se démarquent par leur adaptabilité parfaite à votre structure de loisir. Notre solution se distingue par son efficacité et sa capacité à offrir une </span>digitalisation sur mesure et une expérience client personnalisée à votre image.  </p>
        </div>
        <div className="locHome">
          <BowlingLocations />
        </div>

      </div>
    </>
  );
}

export default Home;
