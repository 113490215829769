import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, Link } from 'react-router-dom'; // Import useLocation
import Offre from "../../../Model/Offre";
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import cookies from "js-cookie";
import CarriereForm from "../Forms/Carriere/CarriereForm";

const DetailsOffres = (props) => {
  const location = useLocation(); // Use useLocation hook
  const locationState = location.state; 
  const title = location.pathname || ""; 
  const { t } = props;
  const splitState = title.split('/');
  splitState[2] = splitState[2]?.replace(/[^a-zA-Z0-9.-]/g, "");

  const [offres, setOffres] = useState([]);
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const [loading, setLoading] = useState(true);
  const currentLanguage = cookies.get('i18next') || 'fr';

  useEffect(() => {
    setLangue(currentLanguage);
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';
  }, [currentLanguage]);

  const scrollIntop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(`${URL_Back}/wp-json/wp/v2/offres?_embed&per_page=100`)
      .then(res => res.json())
      .then(result => {
        const Offrelanguage = [];
        result.forEach(element => {
          if (currentLanguage === "fr" && element.categories[0] === 68) {
            Offrelanguage.push(element);
          } else if (currentLanguage === "en" && element.categories[0] === 86) {
            Offrelanguage.push(element);
          } else if (currentLanguage === "ar" && element.categories[0] === 84) {
            Offrelanguage.push(element);
          }
        });
        setOffres(Offrelanguage);
        setLoading(false);
      });

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(result => {
        setPages(result);
      });
  }, []);

  let offre = [];
  offres.forEach(element => {
    if (locationState !== undefined && splitState[3] !== element.slug) {
      offre.push(new Offre(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered, element.slug));
    }
  });

  let offrepage = [];
  offres.forEach(element => {
    if ((splitState[1].length === 2 ? splitState[3] : splitState[2]) === element.slug) {
      offrepage.push(new Offre(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered));
    }
  });

  let page = [];
  pages.forEach(element => {
    if (decodeURI(element.slug) === (splitState[1].length === 2 ? decodeURI(splitState[2]) : decodeURI(splitState[1]))) {
      page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered));
    }
  });

  let titre = offrepage[0] !== undefined ? offrepage[0].title.replaceAll("&#8217;", "'") : "...Loading";

  return (
    <>
    
      <div className="container-fluid">
        <TitleOfOffres>
          <h2>{locationState ? locationState.title : offrepage[0]?.title || "Titre"}</h2>
        </TitleOfOffres>
        <Descriptif>
          {locationState ? (
            <div className={langue === "ar" ? "div-ar" : ""} dangerouslySetInnerHTML={{ __html: locationState.content }} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: offrepage[0]?.content || "" }} />
          )}
         
        </Descriptif>
        <CarriereForm showOffres = {false} />
        
      </div>
    </>
  );
};

const TitleOfOffres = styled.div` 
  margin: 30px 15% 0px 15%;
    text-align: left;
    padding: 50px 50px 0px;
  .carriere h1{
   display:none ;
  }
  @media (max-width:991px) {
    margin: 30px 10%;
  }
  >ul {
    display: flex;
    list-style-type: none;
    justify-content: center;
    >.text{
    padding-left:5px;
    padding-right: 40px;
    }
  }
`;

const Descriptif = styled.div`
  margin: 30px 15% 0px 15%;
  text-align:left;
  @media (max-width:991px) and (min-width: 675px) {
    margin: 30px 0%;;
  }
  @media (max-width:674px) {
    margin: 30px 0%;
  }
  >div {
    >div {
    padding: 50px 50px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: #000;
    font-weight: 400;
    font-size: 18px;
    font-family: interRegular;
     @media (max-width:991px){
         padding: 15px 25px 0px;
     }
    >ul{
      list-style-image:url('/images/arrow-right 1.png');
      line-height: 28px;
      margin-bottom: 26px;
      @media (min-width: 992px) {
        padding: 0 50px;
      }
      >li{
        padding-left: 10px;
        list-style-position : outside;
      }
    }
    >.lastOne{
        margin-bottom:0;
      }
  }
    >ul{
    display: flex;
    list-style-type: none !important;
    justify-content: left;
    padding-left: 50px;
        font-family: 'InterRegular';
        >label{
        padding-left:5px;
        padding-right: 40px;
        @media (max-width: 605px) and (min-width:490px){
          padding-right:30px;
        }
        @media (max-width:489px) {
          padding-right: 10px;
        }
        }
      }
      h3{
      font-size: 22px;
      margin-bottom: 16px;
      }
      >.lastOne{
        margin-bottom:0;
      }
      
    }
  >.div-ar{
    >div >ul{
      list-style-image:url('/images/icons/arrow left.svg');
    }

  }
  >.buttonPostuler{
    background-color:#ECF6FB;
    padding:15px 0 30px 0;
    border-bottom-left-radius:15px;
    border-bottom-right-radius:15px;
    text-align: center;
    >.link{
      text-decoration: none;
      >button{
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
 }
`;

const LesOffres = styled.div`
 
  
  .offre {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Pour espacer uniformément les colonnes */
  }

  .offre > div {
    width: calc(33.333% - 10px); /* Chaque carte prend environ un tiers de la largeur */
    margin-bottom: 20px; /* Espacement entre les lignes */
    box-sizing: border-box; /* Assure que la largeur inclut le padding et la bordure */
  }

  @media (max-width: 1200px) {
    .offre > div {
      width: calc(50% - 10px); /* 2 cartes par ligne sur les écrans de moins de 991px */
    }
  }

  @media (max-width: 640px) {
    .offre > div {
      width: 100%; /* 1 carte par ligne sur les petits écrans */
    }
  }

  .card {
    padding: 20px 20px 30px 20px;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
    overflow: hidden;
    border-bottom: 4px solid #0CA3FC;
    height: 100%;
    width: auto;
  }

  .card-header {
    background-color: transparent;
    border-bottom: 0;
    font-family: interRegular;
  }

  .card-body {
    padding: 10px 0;
    color: #000;
    font-weight: bold;
  }

`;



export default withTranslation()(DetailsOffres);
