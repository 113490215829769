export default class FooterD {
  constructor(id, title, content, excerpt, date, image) {
      this.id = id;
      this.title = title;
      this.content = content;
      this.excerpt = excerpt;
      this.date = date;
      this.image = image;
  }

  setId(id) {
      this.id.set(id);
  }
  getId(id) {
      return this.age.get(id);
  }

  setTitle(title) {
      this.title.set(title);
  }
  getTitle(title) {
      return this.title.get(title);
  }

  setContent(content) {
    this.content.set(content);
  }
  getContent(content) {
      return this.content.get(content);
  }

  setExcerpt(excerpt) {
    this.excerpt.set(excerpt);
  }
  getExcerpt(excerpt) {
      return this.excerpt.get(excerpt);
  }

  setDate(date) {
      this.date.set(date);
  }
  getDate(date) {
      return this.date.get(date);
  }

  setImage(image) {
      this.image.set(image);
  }
  getImage(image) {
      return this.image.get(image);
  }


}