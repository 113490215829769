import React from 'react';
import './PartnerSlider.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';

const PartnerSlider = ({ partners }) => {
  return (
    <div className="partner-slider-container">
      <Swiper
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },

          1280: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1920: { 
            slidesPerView: 6, 
            spaceBetween: 30, 
          },
        }}
      >
        {partners.map(partner => (
          <SwiperSlide key={partner.id}>
            <img src={partner.image} alt={partner.title} />
          </SwiperSlide>
        ))}
      </Swiper>

    </div>
  );
};

export default PartnerSlider;
