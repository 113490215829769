import React, { useRef, useEffect } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import Tile from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import 'ol/ol.css'; 
import './MapComponent.css';
import { Feature } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { Style, Icon } from 'ol/style';

const MapComponent = ({ locations }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapContainerRef.current) {
   
      const map = new Map({
        target: mapContainerRef.current,
        layers: [
          new Tile({
            source: new OSM(),
          }),
        ],
        view: new View({
          center: fromLonLat([1.888334, 46.603354]),
          zoom: 5.5,
        }),
      });

      mapRef.current = map;

      return () => {
        map.setTarget(undefined);
      };
    }
  }, []);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;


    const features = locations.map(location => {
      const feature = new Feature({
        geometry: new Point(fromLonLat([location.lng, location.lat])),
        name: location.name,
      });

      feature.setStyle(
        new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: 'https://cdn-icons-png.flaticon.com/512/684/684908.png',
            scale: 0.05,
          }),
        })
      );

      return feature;
    });

    const vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: features,
      }),
    });

   
    const existingVectorLayer = map.getLayers().getArray().find(layer => layer instanceof VectorLayer);
    if (existingVectorLayer) {
      map.removeLayer(existingVectorLayer);
    }
    map.addLayer(vectorLayer);

  }, [locations]);

  return <div ref={mapContainerRef} style={{ height: '500px'}} />;
};

export default MapComponent;
