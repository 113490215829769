import React from 'react';
import './MentionsLegales.css';

const MentionsLegales = () => {
  return (
    <div className="mentions-container">
      <h1 className="mentions-title">Mentions Légales</h1>
      <p className="mentions-text">
        Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs, ci-après l’"Utilisateur", du site oxton-digital.com, ci-après le "Site", les présentes mentions légales. La connexion et la navigation sur le Site par l'Utilisateur implique acceptation intégrale et sans réserve des présentes mentions légales. Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».
      </p>
      <h2 className="mentions-subtitle">Article 1 - l'éditeur</h2>
      <p className="mentions-text">
 L'édition du site est assurée par DIGI SERVICE FOR FUN SAS au capital de 1000 euros, immatriculée au Registre du Commerce et des Sociétés de LIMOGES ci-après l'éditeur.
 
      </p>
      <h2 className="mentions-subtitle">Article 2 - l'hebergeur</h2>
      <p className="mentions-text">
        L'hébergeur du Site est la société OXTON DIGITAL.
      </p>
      <h2 className="mentions-subtitle">Article 3 - accès au site</h2>
      <p className="mentions-text">
        Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découler d'une nécessité de maintenance. En cas de modification, interruption ou suspension du Site, l'Éditeur ne saurait être tenu responsable.
      </p>
      <h2 className="mentions-subtitle">Article 4 - collecte des données</h2>
      <p className="mentions-text">
        Le site est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où il ne collecte aucune donnée concernant les utilisateurs. Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site, sans autorisation de l'Éditeur est prohibée et pourra entraînée des actions et poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.
      </p>
    </div>
  );
};

export default MentionsLegales;
