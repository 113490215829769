import React from 'react'
import chartImage from '../../../../images/qr/qr-code.png';
import connexion from '../../../../images/qr/connexion.png'
import gestion from '../../../../images/qr/gestion.png'
import img from '../../../../images/qr/Group 38985.png'
import './QrCode.css'

const  QrCode=()=> {
  return (
    <div>
    <div className="qr-solution-container ">
        <h1 className="qr-solution-title">Solution QR Code</h1>
        <div className="qr-solution-content mt-5">
            <div className="qr-solution-text">
                <h3>Prises de commandes bar instantanées grâce à des QR CODES uniques</h3>
                <ul>
                    <li>

                        <img src={connexion} className='img-fluid' />

                        <span>Connection au système de caisse du bar</span>
                    </li>
                    <li>

                        <img src={gestion} className='img-fluid' />

                        <span>Gestion et sécurisation des flux monétaires</span>
                    </li>
                    
                </ul>
            </div>
            <div className="qr-solution-image">
                <img src={chartImage} alt="Graph on a laptop" />
            </div>
        </div>
        </div>
        <div className='box'>
            <div className='box-content'>
            <img src={img} className='img-fluid' />
        <h2>Le projet est en cours de développement et sera officiellement lancé en 2025. Restez à l'affût pour découvrir cette innovation prochaine !</h2>
        </div>

        </div>
        </div>
  )
}

export default QrCode