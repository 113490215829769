import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Link } from 'react-router-dom';
import Partenaire from "../../Model/Partenaire";
import Contacter from "../../Model/Contacter";
import FooterD from "../../Model/Footer";
import { URL_Back ,oxton } from "../Pages/Variables";
import { withTranslation } from "react-i18next";
import cookies from "js-cookie";

const Footer = (props) => {
  const [items, setItems] = useState([]);
  const [services, setServices] = useState(false)
  const [contact, setContact] = useState(0)
  const [contact1, setContact1] = useState(0)
  const [partenaires, setPartenaires] = useState(0)
  const [contacter, setContacter] = useState([])
  const [footer, setFooter] = useState([])
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
  }, [currentLanguage])

  useEffect(() => {
    fetch(`${URL_Back}/wp-json/wp/v2/footer?_embed`)
      .then(res => res.json())
      .then((result) => {
        setFooter(result)
      })

    fetch(`${URL_Back}/wp-json/wp/v2/partenaires?_embed`)
      .then(res => res.json())
      .then((result) => {
        setItems(result)
      })

    fetch(`${URL_Back}/wp-json/wp/v2/contacter?_embed`)
      .then(res => res.json())
      .then((result) => {
        setContacter(result)
      })
  }, [])

  let selectedNames = ['Trivec', 'CashDro', 'Verifone', 'Oxton', 'QubicaAMF', 'BowlingMedia'];
  let partenaire = items
    .filter(element => selectedNames.includes(element.title.rendered))
    .map(element => new Partenaire(
      element.id, 
      element.title.rendered, 
      element.excerpt.rendered, 
      element.date, 
      element._embedded['wp:featuredmedia'][0].source_url
    ))
    .reverse();
  
  let contacterTel = [];
  contacter.map(element => {
    if (element.title.rendered === "Telephone-digi") {
      contacterTel.push(new Contacter(
        element.id,
        element.title.rendered,
        element.content.rendered,
        element.excerpt.rendered,
        element.date
      ));
    }
  });

  let footerDescription = [];
  footer.map(element =>
    element.title.rendered === "Description" &&
    (element.excerpt.rendered === "<p>العربية</p>\n" && langue === "ar"
      ? footerDescription.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url))
      : element.excerpt.rendered === "<p>Français</p>\n" && langue === "fr"
        ? footerDescription.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url))
        : element.excerpt.rendered === "<p>English</p>\n" && langue === "en"
        && footerDescription.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url))
    )
  )

  let footerSolutions = [];
  footer.map(element =>
    element.title.rendered === "Solutions" &&
    footerSolutions.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date))
  )
  const solutionsHTML = footerSolutions.length ? footerSolutions[0].content : "";
  const solutionsArray = solutionsHTML.split('</li>');
  const filteredSolutionsArray = solutionsArray.filter(item => item.trim() !== "");
  const firstHalfSolutions = filteredSolutionsArray.slice(0, 3).map(item => item + '</li>').join('');
  const secondHalfSolutions = filteredSolutionsArray.slice(3).map(item => item + '</li>').join('');

  let serviceDigi = []
  footer.map(element =>
    element.title.rendered === "Services-digi" &&
    serviceDigi.push(new FooterD(element.id, element.title.rendered, element.content.rendered, element.excerpt.rendered, element.date))
  )

  return (
    <>
      <div className="allFooter">
        <div className="footer">
          <div className="description">
            <img className="logo logoFooter" src="/images/logoFooter.png" alt="oxton" />
            <p dangerouslySetInnerHTML={{ __html: "Notre solution digitale vous apportera tous les outils nécessaires pour accroître votre chiffre d'affaire et votre panier moyen.Prenez de l'avance sur vos concurrents en abordant le virage numérique et faites de votre centre de loisir une entreprise moderne au yeux de vos clients." }} />
          </div>
          <div className="services">
            <div className="buttonFooter" onClick={() => services ? setServices(false) : setServices(true)}>
              <p className="title half-underline">Solutions</p>
              <span className="hideFooter title">
                {services ? <img src="/images/footer/arrow_up.svg" alt="arrow_up" /> :
                  <img src="/images/footer/arrow_down.svg" alt="arrow_down" />}
              </span>
            </div>
            {footerSolutions[0] !== undefined &&
              <div className={services ? "service show" : "service hide"}>
                <div className="solution-column" dangerouslySetInnerHTML={{ __html: `<ul>${firstHalfSolutions}</ul>` }} />
                <div className="solution-column" dangerouslySetInnerHTML={{ __html: `<ul>${secondHalfSolutions}</ul>` }} />
              </div>
            }
          </div>
          <hr className="hideFooter" />
          <div className="services-contact">
          <div className="contacter">
            <div className="buttonFooter" onClick={() => contact1 ? setContact1(false) : setContact1(true)}>
              <p className="title half-underline">Service</p>
              <span className="hideFooter title">
                {contact1 ? <img src="/images/footer/arrow_up.svg" alt="arrow_up" /> :
                  <img src="/images/footer/arrow_down.svg" alt="arrow_down" />}</span>
            </div>
            {serviceDigi[0] !== undefined && (
              <ul  className={contact1
                ? langue === "ar" ? "UlContacter UlContacter-ar show" : "UlContacter show"
                : langue === "ar" ? "UlContacter UlContacter-ar hide" : "UlContacter hide"} dangerouslySetInnerHTML={{ __html: serviceDigi[0].content }} />
            )}

            <hr className="hideFooter" />
            <div className="contacter">
              <div className="buttonFooter" onClick={() => contact ? setContact(false) : setContact(true)}>
                <p className="title half-underline">Contact</p>
                <span className="hideFooter title">
                  {contact ? <img src="/images/footer/arrow_up.svg" alt="arrow_up" /> :
                    <img src="/images/footer/arrow_down.svg" alt="arrow_down" />}</span>
              </div>
              <ul className={contact
            ? langue === "ar" ? "UlContacter UlContacter-ar show" : "UlContacter show"
            : langue === "ar" ? "UlContacter UlContacter-ar hide" : "UlContacter hide"}>
                {contacterTel.map((item, key) => (
                  <li key={key}>
                    <img className="contacterImg" src="/images/footer/telephone (2).svg" alt="telephone" />
                    <a className="aLink" href={`tel:${item.content.slice(4, -5)}`}><bdi>{item.content.slice(4, -5)}</bdi></a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          </div>
          <hr className="hideFooter" />
          <div className="partenaires">
            <div className="buttonFooter" onClick={() => partenaires ? setPartenaires(false) : setPartenaires(true)}>
              <div className="title half-underline">Nos Partenaires</div>
              <span className="hideFooter title">
                {partenaires ? <img src="/images/footer/arrow_up.svg" alt="arrow_up" /> :
                  <img src="/images/footer/arrow_down.svg" alt="arrow_down" />}</span>
            </div>
            <div className={partenaires ? "group show groupShow" : "group hide grouphide"}>
              {partenaire.map(item => (
                <a href={item.excerpt.slice(3, -5)} target="_blank" rel="noreferrer" key={item.id} >
                  <img className={langue === "ar" ? "partenaire partenaire-ar" : "partenaire"} src={item.image} alt={item.title} />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="copyright d-flex">
          <bdi><span> Copyright &copy; {new Date().getFullYear()} -<Link to={oxton} target="_blank" rel="noopener noreferrer"> Oxton Digital.</Link></span>
            <Link to={`/${langue}/cgv`}>CGV |&nbsp;</Link>
            <Link to="/fr/mentions-legales">Mentions légales</Link></bdi>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(Footer);
