import React from 'react';
import './ErrorPage.css';

const ErrorPage = () => {
    return (
        <div className="error-page">
            <div className="content">
                <div className="icon">
          
                    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" fill="#FF4D4D" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24l0 112c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-112c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
                </div>
                <h1>Oups ! Une erreur est survenue.</h1>
                <p>Nous avons rencontré un problème lors de l'envoi de vos données. Veuillez réessayer plus tard.</p>
                <button className="retry-button">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <span>Réessayer</span>
                    </div>
                </button>
            </div>
        </div>
    );
}

export default ErrorPage;
