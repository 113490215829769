import Offres from "../../Offres/Offres";
import Cf7FormWrapper from "../ContactPage/Cf7FormWrapper";
import Contact from "./Carriere";

const CarriereForm = ({ showOffres = true, ...props }) => {
  return (
    <>
    {showOffres && <Offres />}
    <Cf7FormWrapper id={1635}>
     
      <Contact subprops={props} />
    </Cf7FormWrapper>
    </>
  );
};

export default CarriereForm;
