export default class Menu {
    constructor(db_id, title, child_items, slug, verif) {
        this.db_id = db_id;
        this.title = title;
        this.child_items = child_items;
        this.slug = slug;
        this.verif = verif;
    }

    setdb_id(db_id) {
        this.db_id.set(db_id);
    }
    getdb_id(db_id) {
        return this.db_id.get(db_id);
    }

    setTitle(title) {
        this.title.set(title);
    }
    getTitle(title) {
        return this.title.get(title);
    }

    setchild_items(child_items) {
        this.child_items.set(child_items);
    }
    getchild_items(child_items) {
        return this.child_items.get(child_items);
    }

    setslug(slug) {
        this.slug.set(slug);
    }
    getslug(slug) {
        return this.slug.get(slug);
    }

}